<template>
    <div v-if="this.pageData" class="contacts-page">
        <HeaderIntroImage marginbottom :headerMetadata="this.pageData" />
        <main>
            <section class="green-banner">
                <div class="container">
                    <h3>
                        Send us an email
                    </h3>
                    <h4><a href="mailto:displacedscientists@twas.org">displacedscientists@twas.org</a>
                    </h4>
                </div>
            </section>
            <section class="main-content">
                <div class="container">
                    <div class="social">
                        <h2>
                            Follow <span>#ScienceInExile</span>
                        </h2>
                        <div>
                            <div class="box">
                                <a href="https://www.twas.org" target="_blank">
                                    <img src="../assets/logo-twas.png" alt="twas logo">
                                </a>
                                <p>Trieste - Italy</p>
                                <div>
                                    <a href="https://twitter.com/TWASnews" target="_blank" class="tw"></a>
                                    <a href="https://www.facebook.com/TWAS.Science" target="_blank" class="fb"></a>
                                    <a href="https://www.youtube.com/user/twasvideos" target="_blank" class="yt"></a>
                                    <a href="https://www.flickr.com/photos/twas" target="_blank" class="fl"></a>
                                </div>
                            </div>

                            <div class="box">

                                <a href="https://www.interacademies.org" target="_blank">
                                    <img src="../assets/logo-iap.png" alt="iap logo">
                                </a>
                                <p>Trieste - Italy</p>

                                <div>
                                    <a href="https://twitter.com/IAPartnership" target="_blank" class="tw"></a>
                                    <a href="https://www.linkedin.com/company/interacademypartnership" target="_blank"
                                        class="ln"></a>
                                    <a href="https://tinyurl.com/IAPyoutube" target="_blank" class="yt"></a>
                                </div>

                            </div>

                            <div class="box">
                                <a href="https://council.science/" target="_blank">
                                    <img src="../assets/logo-isc.svg" alt="isc logo">
                                </a>
                                <p>France - Paris</p>

                                <div>
                                    <a href="https://twitter.com/ISC" target="_blank" class="tw"></a>
                                    <a href="https://www.linkedin.com/company/international-science-council/"
                                        target="_blank" class="ln"></a>
                                    <a href="https://www.facebook.com/InternationalScience/" target="_blank"
                                        class="fb"></a>
                                    <a href="https://www.instagram.com/council.science" target="_blank" class="in"></a>
                                    <a href="https://www.youtube.com/c/InternationalScienceCouncil" target="_blank"
                                        class="yt"></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="map"></section>
            <!-- <section class="main-content map">
                <div class="container">
                      <div class="location">
                        <div class="box">
                            <h3>sede iap</h3>
                            <p class="adress">Via Grignano, 34151</p>
                            <p class="city">TRIESTE  -  Italy</p>
                        </div>
                        <div class="box">
                            <h3>sede TWAS</h3>
                            <p class="adress">Via Grignano, 34151</p>
                            <p class="city">TRIESTE  -  Italy</p>
                        </div>
                        <div class="box">
                            <h3>sede iap</h3>
                            <p class="adress">Via Grignano, 34151</p>
                            <p class="city">TRIESTE  -  Italy</p>
                        </div>
                    </div>
                </div>
            </section> -->
        </main>
    </div>
</template>

<script>
    import HeaderIntroImage from '../components/layout-blocks/HeaderIntroImage.vue'
    import {
        fetchSinglePage
    } from '../libs/drupalClient'
    export default {
        name: 'contacts',
        components: {
            HeaderIntroImage
        },
        data: () => {
            return {
                pageData: null,
                currentPageID: "e0403b61-1ef7-4fd7-ba1e-d37254a159ce"
            }
        },
        mounted() {
            fetchSinglePage(this.currentPageID, {
                    include: ['field_page_banner', 'field_page_images', 'field_ref_ui_text']
                })
                .then(res => {
                    this.pageData = res
                    //console.log('pagedata', res)
                })
        }
    }
</script>

<style lang="scss" scoped>
    .header-intro {

        ::v-deep .container {
            position: relative;
            z-index: 1;
        }
    }

    .green-banner {
        text-align: center;
        color: #fff;

        .container {
            padding: 1rem 1rem;
        }

        h4 {
            font-size: 1.125rem;
            font-weight: 400;

            a {
                color: #fff;
            }
        }

        h3 {
            font-size: 1rem;
        }
    }

    main {
        background: linear-gradient(to bottom, transparent 1rem, #EBF0F5 1rem, #EBF0F5 100%);
    }

    .main-content {
        padding-bottom: 0;
    }

    .map {
        background-repeat: no-repeat;
        background-position: bottom;
        background-image: url("../assets/map-contact.svg");
        background-color: #EBF0F5;
        opacity: 0;
        padding-bottom: 2rem;
    }

    .social {
        padding: 2rem 0rem 0rem 0rem;

        h2 {
            text-align: center;
            color: #0A1F54;
            font-weight: 400;
            font-size: 1.375rem;

            span {
                font-weight: 600;
                color: #83BA26;
            }
        }

        .box {
            padding: 2rem 1rem;
            margin-top: 1rem;
            background-color: #fff;

            img {
                margin: 0px auto 0px auto;
                display: block;
                width: 80%;
                height: auto;
                max-height: 90px;
                max-width: 250px;
                object-fit: contain;
            }

            >div {
                display: flex;
                justify-content: center;

                a {
                    &:hover {
                        opacity: 0.8;
                        transition: 0.2s;
                    }

                    transition: 0.2s;
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center;
                    display: block;
                    padding: 10px;
                    width: 20px;
                    margin: 5px;
                    height: 20px;

                    &.fl {
                        background-image: url("../assets/icon-flickr.svg");
                    }

                    &.tw {
                        background-image: url("../assets/icon-twitter.svg");
                    }

                    &.fb {
                        background-image: url("../assets/icon-facebook.svg");
                    }

                    &.yt {
                        background-image: url("../assets/icon-youtube.svg");
                    }

                    &.ln {
                        background-image: url("../assets/icon-linkedin.svg");
                    }

                    &.in {
                        background-image: url("../assets/icon-instagram.svg");
                    }
                }
            }
        }


    }

    .location {

        .box {
            margin-bottom: 2rem;
            padding-left: 3rem;
            padding-top: 1rem;
            position: relative;

            &:before {
                position: absolute;
                left: 0;
                content: url("../assets/icon-pin.svg");
            }
        }

        h3 {
            margin-bottom: 1rem;
            color: #0A1F54;
            position: relative;
            font-size: 18px;
            text-transform: uppercase;
        }

        .adress {
            color: #0A1F54;
            font-size: 1.125rem;
            font-weight: 500;
            margin: 0;
            margin-bottom: 7px;
        }

        .city {
            color: #209FE1;
            font-size: 0.875rem;
            font-weight: 500;
            margin: 0;
            margin-bottom: 7px;
        }

    }

    .box p {
        display: block;
        font-size: 14px;
        color: #209fe1;
        text-align: center;
        font-weight: 600;
        margin: 1rem auto;
    }

    @media (min-width:992px) {
        .green-banner .container {
            padding: 3rem 1rem;

            h3 {
                font-size: 24px;
            }

            h4 {
                font-size: 28px;
            }

        }

        .social {

            h2 {
                font-size: 1.875rem;
                margin: 2rem 0rem 1rem 0rem;
            }

            >div {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .box {
                    width: 29%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    >a {
                        img {
                            width: 100%;
                            max-width: 236px;
                            max-height: 64px;
                            margin-bottom: 2rem;
                        }
                    }
                }
            }
        }
    }

    @media (min-width: 1024px) {
        .map {
            opacity: 1;
            padding-bottom: 2rem;
            min-height: 85vh;
            background-position: center;
        }

        .box p {
            display: none;
        }
    }

    @media (min-width: 1200px) {
        .map {
            opacity: 1;
            padding-bottom: 2rem;
            min-height: 80vh;
            background-position: top center;
        }
    }

    @media (min-width: 1500px) {
        .map {
            opacity: 1;
            padding-bottom: 2rem;
            min-height: 63vh;
            background-position: top center;
        }
    }
</style>